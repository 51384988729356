'use strict';

require('../utils/jquery-extensions.js');

var $ = require('jquery');
var adSlotSettings = require('./slotSettings.js');
var layout = require('../ui/layout.js');
var logger = require('../utils/logger.js');
var sticky = require('../ui/sticky.js');
var timer = require('../utils/timer.js');
var config = require('../utils/config.js');
var analytics = require('../utils/analytics.js');
var ozoneProvider = require('./providers/ozone-provider.js');

var classNames = {
	siteRoot: 'site-root',
	topAd: 'top-ad',
	topAdContainer: 'top-ad-container'
};

var modifierNames = {
	loaded: 'loaded',
	viewed: 'viewed'
};

var googletag;

var adSlots = {};
var definedSlots = [];
var slotSettings = adSlotSettings.slotSettings;

var providers = [ozoneProvider];
var providerEventDetails = {};

var adConfig;

var events = {
	init: 'onInit',
	defineSlot: 'onDefineSlot',
	requestAds: 'onRequestAds',
	slotTargeting: 'onSlotTargeting'
};

var mpuAds = [];
var definedAdSlots = [];

function setVisibleMpuAds() {
	var numOfVisibleMpuAds = $('.mpu-ad:visible').toArray();

	for (var i = 0; i <= numOfVisibleMpuAds.length - 1; i++) {
		mpuAds.push(
			{
				id: numOfVisibleMpuAds[i].id,
				sizes: slotSettings.mpu.sizes
			}
		);
	}
}

function isSlotVisible(slotId) {
	return $('#' + slotId).is(':visible');
}

function resizeStickyLeaderboard(size) {
	if (!size) {
		return;
	}

	var $leaderboardAd = $('#' + slotSettings.leaderboard1.id);
	$leaderboardAd.css('width', size[0]);
	$leaderboardAd.css('height', size[1]);
}

var callbacks = {
	requestInitialAdsCallback: function () {
		googletag.cmd.push(function () {
			logger.debug('GAM: Load initial ads - after ' + logger.secondsSincePageStart());
			for (var i = 0; i < definedSlots.length; i++) {
				googletag.display(definedSlots[i]);
			}
			
			googletag.pubads().refresh(definedAdSlots);
		});

		logger.debug('GAM: Defined initial slots - after ' + logger.secondsSincePageStart());
	},

	loadAdsCallback: function () {
		googletag.cmd.push(function () {
			logger.debug('GAM: Reload ads');
			
			googletag.pubads().refresh(definedAdSlots);
		});
	},

	loadInfiniteEditorialAdsCallback: function () {
		googletag.cmd.push(function () {
			//remove overylay from definedAdSlots
			var adSlots  = definedAdSlots.filter(function (item) {return item.id !== slotSettings.overlay; });
			googletag.pubads().refresh(adSlots);
		});
	},

	loadGalleryAdsCallback: function () {
		googletag.cmd.push(function () {
			logger.debug('GAM: Reload Gallery ad');
			var slotsToLoad = [adSlots.mpuGallery];
			googletag.pubads().refresh(slotsToLoad);
		});
	}
};

var gamEventHandlers = {
	onSlotOnLoad: {
		'leaderboard-1': function () {
			$('#' + slotSettings.leaderboard1.id).addBemClass(classNames.topAdContainer, classNames.topAd, modifierNames.loaded);
		}
	},
	onSlotRenderEnded: {
		'leaderboard-1': function (event) {
			resizeStickyLeaderboard(event.size);
		}
	},
	onImpressionViewable: {
		'leaderboard-1': function () {
			$('#' + slotSettings.leaderboard1.id).addBemClass(classNames.topAdContainer, classNames.topAd, modifierNames.viewed);
			sticky.unsetStickyLeaderboard();
		}
	}
};

function providerComplete(eventId, providerId) {
	/*jshint validthis: true */

	var eventDetails = providerEventDetails[eventId];
	if (!eventDetails) {
		// Must have timed out and removed the event already
		return false;
	}

	timer.pause(eventDetails.timeout);
	
	var index = -1;
	eventDetails.providers.some(function (element, i) {
		if (element.id === providerId) {
			index = i;
			return true;
		}
	});

	if (index >= 0) {
		eventDetails.providers[index].isComplete = true;
	}

	var allComplete = eventDetails.providers.every(function (item) {
		return item.isComplete === true;
	});

	if (allComplete) {
		delete providerEventDetails[eventId];

		if (eventDetails.callback && $.isFunction(eventDetails.callback)) {
			timer.cancel(eventDetails.timeout);
			logger.debug('GAM: All providers complete');
			eventDetails.callback.apply(this, eventDetails.arguments);
		}
	} else {
		timer.resume(eventDetails.timeout);
	}
}

function providerTimeout(eventId) {
	/*jshint validthis: true */
	var eventDetails = providerEventDetails[eventId];

	delete providerEventDetails[eventId];

	if (eventDetails.callback && $.isFunction(eventDetails.callback)) {
		logger.debug('GAM: Provider timeout');
		eventDetails.callback.apply(this, eventDetails.arguments);
	}
}

// Note that no ad provider scripts are currently implemented but leaving the mechanism in place in case they become required again
function fireProviderEvent(event, eventData, callback, args) {
	var eventId = event + '_' + Date.now();
	var eventProviders = providers.filter(function (provider) {
		return provider[event];
	});

	if (callback) {
		var eventDetails = {
			eventId: eventId,
			callback: callback,
			arguments: args,
			providers: eventProviders.map(function (provider) {
				var result = {
					id: provider.getId(),
					isComplete: false
				};

				return result;
			})
		};

		providerEventDetails[eventId] = eventDetails;

		// If we have no providers, don't bother waiting, just invoke the timeout
		if (eventProviders.length > 0) {
			providerEventDetails[eventId].timeout = timer.begin(function () {
				providerTimeout(eventId);
			}, adConfig.bidProviderTimeout > 0 ? adConfig.bidProviderTimeout : 5000);
		} else {
			providerTimeout(eventId);
			return;
		}
	}

	var data = {
		eventId: eventId,
		eventData: eventData,
		onCompleteCallback: providerComplete
	};

	eventProviders.forEach(function (provider) {
		provider[event](data);
	});
}

function setVisibility($context) {
	// add or remove ID attribute based on whether ad is visible or not so there are no <div> tags with duplicate IDs
	$('.advert[data-id]', $context).each(function () {
		var $adContainer = $(this);
		if ($adContainer.parent().is(':visible')) {
			var dataVariable = 'data-id';

			$adContainer.attr('id', $adContainer.attr(dataVariable));
		} else {
			$adContainer.removeAttr('id');
		}
	});
}

// Call this within a googletag.cmd.push function
function setTargeting() {

	for (var key in adConfig.targetingKeyValues) {
		if (adConfig.targetingKeyValues.hasOwnProperty(key)) {
			var value = adConfig.targetingKeyValues[key];

			googletag.pubads().setTargeting(key, [value]);
		}
	}

	googletag.pubads().setTargeting('pageview_id', config.pageViewId);

	var paidTrafficValue = 'no';
	
	if (config.ppcTrackingParams) {
		paidTrafficValue = 'yes';

		var params = config.ppcTrackingParams;
		var paidCampaignValue = `${params.utmMedium}|${params.utmSource}|${params.trackingIdName},${params.trackingId}`;
		googletag.pubads().setTargeting('paidcampaign', paidCampaignValue);
	}
		
	googletag.pubads().setTargeting('paidtraffic', paidTrafficValue);

	logger.debug('GAM: Targetting set - after ' + logger.secondsSincePageStart());
}

// Call this within a googletag.cmd.push function
function defineAdSlot(adSlot) {
	var slotSize = layout.isMobile() ? adSlot.sizes.mobile : adSlot.sizes.desktop;
	var adUnitPath = '/24156345/' + adConfig.site + '/' + adConfig.sectionName;

	var slot = googletag.defineSlot(adUnitPath, slotSize, adSlot.id)
		.setTargeting('position', [adSlot.id]);

	fireProviderEvent(events.slotTargeting, slot);

	slot.addService(googletag.pubads());

	if (adSlot.sizes.sizeMapping) {
		slot.defineSizeMapping(adSlot.sizes.sizeMapping);
	}

	definedSlots.push(adSlot.id);
	return slot;
}

function defineAdSlots() {
	setVisibleMpuAds();

	if (mpuAds.length > 0) {
		mpuAds.forEach(function (adSlot) {
			fireProviderEvent(events.defineSlot, adSlot);
		});
	}

	fireProviderEvent(events.defineSlot, slotSettings.leaderboard1);
	fireProviderEvent(events.defineSlot, slotSettings.inlineFooter);
	fireProviderEvent(events.defineSlot, slotSettings.overlay);

	if (isSlotVisible(slotSettings.gumGum.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.gumGum);
	}

	if (isSlotVisible(slotSettings.seedTag.id)) {
		fireProviderEvent(events.defineSlot, slotSettings.seedTag);
	}

	googletag.cmd.push(function () {
		logger.debug('GAM: Starting initial slot definition - after ' + logger.secondsSincePageStart());

		adSlots.leaderboard1 = defineAdSlot(slotSettings.leaderboard1);
		adSlots.inlineFooter = defineAdSlot(slotSettings.inlineFooter);
		adSlots.overlay = defineAdSlot(slotSettings.overlay);
		definedAdSlots.push(adSlots.leaderboard1);
		definedAdSlots.push(adSlots.inlineFooter);
		definedAdSlots.push(adSlots.overlay);		

		if (isSlotVisible(slotSettings.gumGum.id)) {
			adSlots.gumGum = defineAdSlot(slotSettings.gumGum);
			definedAdSlots.push(adSlots.gumGum);
		}

		if (isSlotVisible(slotSettings.seedTag.id)) {
			adSlots.seedTag = defineAdSlot(slotSettings.seedTag);
			definedAdSlots.push(adSlots.seedTag);
		}

		if (mpuAds.length > 0) {
			mpuAds.forEach(function (adSlot) {
				var mpuAdSlot = defineAdSlot(adSlot);
				definedAdSlots.push(mpuAdSlot);
			});
		} 

		setTargeting();

		googletag.pubads().enableSingleRequest();

		googletag.pubads().enableLazyLoad({
			fetchMarginPercent: 25,
			renderMarginPercent: 5,
			mobileScaling: 2.0
		});

		googletag.cmd.push(function () {
			// _pdfps stands for Permutive DFP Storage
			if (googletag.pubads().getTargeting('permutive').length === 0) {
				var keyValuePairs = window.localStorage.getItem('_pdfps');
				googletag.pubads().setTargeting('permutive', keyValuePairs ? JSON.parse(keyValuePairs) : []);
			}
		});

		googletag.cmd.push(function () {
			var vendorName = 'covatic';
			if (googletag.pubads().getTargeting(vendorName).length === 0) {
				var keyValuePairs = window.localStorage.getItem('_audienceProfile');
				googletag.pubads().setTargeting(vendorName, keyValuePairs ? keyValuePairs : []);
			}
		});

		googletag.pubads().addEventListener('slotOnload', function (event) {
			var slotElementId = event.slot.getSlotElementId();
			logger.debug('GAM: slotOnLoad fired for slot ' + slotElementId + ' - after ' + logger.secondsSincePageStart());
			
			if (gamEventHandlers.onSlotOnLoad.hasOwnProperty(slotElementId)) {
				gamEventHandlers.onSlotOnLoad[slotElementId](event);
			}
		});

		googletag.pubads().addEventListener('slotRenderEnded', function(event) {
			var slotElementId = event.slot.getSlotElementId();
			logger.debug('GAM: slotRenderEnded fired for slot ' + slotElementId + ' - after ' + logger.secondsSincePageStart());
			
			if (gamEventHandlers.onSlotRenderEnded.hasOwnProperty(slotElementId)) {
				gamEventHandlers.onSlotRenderEnded[slotElementId](event);
			}
		});

		googletag.pubads().addEventListener('impressionViewable', function(event) {
			var slotElementId = event.slot.getSlotElementId();
			logger.debug('GAM: impressionViewable fired for slot ' + slotElementId + ' - after ' + logger.secondsSincePageStart());

			if (gamEventHandlers.onImpressionViewable.hasOwnProperty(slotElementId)) {
				gamEventHandlers.onImpressionViewable[slotElementId](event);
			}
		});		

		analytics.addDataLayerValues({
			'potentialAdCount': definedAdSlots.length
		});

		googletag.enableServices();
		fireProviderEvent(events.requestAds, null, callbacks.requestInitialAdsCallback);
	});
}

function initInjectedMpus() {
	var $mpuInjectionPoints = $('.mpu-inject:visible');
	var initialInjectedAdIndex = $('[id^=mpu-]').filter('[id!=mpu-gallery]').length + 1;
	var maxAdInjections = adConfig.maxMpuCount - initialInjectedAdIndex; // max ad slots minus already existing ad slots

	// Add an adslot to each injection point
	$mpuInjectionPoints.each(function (index, element) {
		var adIndex = index + initialInjectedAdIndex;
		var $injectionElement = $(element);

		if (adIndex > maxAdInjections) {
			$injectionElement.remove();
			return;
		}

		var slotId = 'mpu-' + adIndex;
		var $slotElement = $injectionElement.find('.advert');
		$slotElement.addClass('mpu-ad').attr('id', slotId);
	});
}

module.exports = {
	init: function (config) {
		logger.debug('Initialising ads - after ' + logger.secondsSincePageStart());

		adConfig = config;

		// initialise googletag, if it doesnt already exist
		googletag = window.googletag = window.googletag || {};
		googletag.cmd = googletag.cmd || [];

		googletag.cmd.push(function () {
			googletag.pubads().disableInitialLoad();
		});

		fireProviderEvent(events.init, adConfig);

		setVisibility();
		initInjectedMpus();
		defineAdSlots();

		if (window.location.search.indexOf('takeover=1') > 0) {
			$.findByBem(classNames.siteRoot).addBemClass(classNames.siteRoot, null, 'has-takeover');
		}
	},

	loadAds: function ($context) {
		setVisibility($context);
		fireProviderEvent(events.requestAds, null, callbacks.loadAdsCallback);
	},

	loadInfiniteEditorialAds: function ($context) {
		setVisibility($context);

		googletag.cmd.push(function () {
			fireProviderEvent(events.requestAds, null, callbacks.loadInfiniteEditorialAdsCallback);
		});
	},

	loadGalleryAds: function ($context) {
		if ($context) {
			setVisibility($context);
		}

		googletag.cmd.push(function () {
			if (definedSlots.indexOf(slotSettings.mpuGallery.id) === -1) {
				fireProviderEvent(events.defineSlot, slotSettings.mpuGallery);

				logger.debug('GAM: Defining mpu-gallery ad');
				adSlots.mpuGallery = defineAdSlot(slotSettings.mpuGallery);

				googletag.display(slotSettings.mpuGallery.id);
			}

			fireProviderEvent(events.requestAds, null, callbacks.loadGalleryAdsCallback);
		});
	}
};