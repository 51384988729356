'use strict';

function initOzone() {
	window.ozoneWrapper = window.ozoneWrapper || {};
	window.ozoneWrapper.cmd = window.ozoneWrapper.cmd || [];
}

module.exports = {
	onInit: function () {
		initOzone();
	}
};